import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//Limpar Cache - VUE.Js Inicio
Vue.directive('cache', {
  inserted: function (el) {
    el.setAttribute('key', Date.now());
  }
})
/*
Vue.use((req, res, next) => {
  res.setHeader('Cache-Control', 'no-store, no-cache, must-revalidate, proxy-revalidate');
  res.setHeader('Pragma', 'no-cache');
  res.setHeader('Expires', '0');
  res.setHeader('Surrogate-Control', 'no-store');
  next();
});
*/
//Limpar Cache - VUE.Js Fim


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/index.html',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/',
      name: 'inicial',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/Login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/login.aspx',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/perfil',
      name: 'Perfil',
      component: () => import('@/views/Perfil/Perfil.vue'),
      meta: {
        pageTitle: 'Perfil',
        breadcrumb: [
          {
            text: 'Perfil',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/second-page',
    //   name: 'second-page',
    //   component: () => import('@/views/SecondPage.vue'),
    //   meta: {
    //     pageTitle: 'Second Page',
    //     breadcrumb: [
    //       {
    //         text: 'Second Page',
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    {
      path: '/DocumentosExternos/:path',
      name: 'documentoNavAberto',
      component: () => import('@/views/Documentos/DocumentoNavAberto.vue'),
      meta: {
        layout: 'full',
      },
    },


    {
      path: '/documentoNav/:path',
      name: 'documentoNav1',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav2',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav3',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav4',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav5',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav6',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav7',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav8',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav9',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav10',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav11',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav12',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav13',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav14',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav15',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav16',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav17',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav18',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav19',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav20',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav21',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav22',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav23',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav24',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav25',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav26',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav27',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav28',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav29',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/documentoNav/:path',
      name: 'documentoNav30',
      component: () => import('@/views/Documentos/DocumentoNav.vue'),
    },
    {
      path: '/DevProduto',
      name: 'DevProduto',
      component: () => import('@/views/DesenvolvimentoProduto/SolicitacaoDesenvolvimento.vue'),
    },
    {
      path: '/DevProdutoFormulario',
      name: 'DevProdutoFormulario',
      component: () => import('@/views/DesenvolvimentoProduto/FormularioSolicitacao.vue'),
    },
    {
      path: '/DevProdutoFormulario/:solidesenvolvimentoid',
      name: 'DevProdutoFormulario',
      component: () => import('@/views/DesenvolvimentoProduto/FormularioSolicitacao.vue'),
    },
    {
      path: '/DevProdutoFormularioAnalise/:solidesenvolvimentoid',
      name: 'DevProdutoFormularioAnalise',
      component: () => import('@/views/DesenvolvimentoProduto/FormularioAnalise.vue'),
    },
    {
      path: '/DevProdutoFormularioDev/:solidesenvolvimentoid',
      name: 'DevProdutoFormularioDev',
      component: () => import('@/views/DesenvolvimentoProduto/FormularioDesenvolvimento.vue'),
    },
    {
      path: '/DevProdutoFormularioAMO/:solidesenvolvimentoid',
      name: 'DevProdutoFormularioAMO',
      component: () => import('@/views/DesenvolvimentoProduto/FormularioAMO.vue'),
    },
    {
      path: '/Relatorios',
      name: 'Relatorios',
      component: () => import('@/views/Report/Relatorios.vue'),
    },
    {
      path: '/relTermoDevolBemMovel',
      name: 'relTermoDevolBemMovel',
      component: () => import('@/views/Report/RelTermoDevolBemMovel.vue'),
    },
    {
      path: '/relTermoRespoBemMovel',
      name: 'relTermoRespoBemMovel',
      component: () => import('@/views/Report/RelTermoRespoBemMovel.vue'),
    },
    {
      path: '/dashPainelLogistico',
      name: 'dashPainelLogistico',
      component: () => import('@/views/Report/DashPainelLogistico.vue'),
    },
    //from Rafael Nextage
    {
      path: '/cadastroAgendamentoEntrega',
      name: 'cadastroAgendamentoEntrega',
      component: () => import('@/views/AgendamentoEntrega/AgendamentoEntrega.vue'),
      props: true,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/editarAgendamentoEntrega',
      name: 'editarAgendamentoEntrega',
      component: () => import('@/views/AgendamentoEntrega/AgendamentoEntrega.vue'),
      props: true,
    },
    //from Sergio Nextage
    {
      path: '/agendamentoEntregaLista',
      name: 'agendamentoEntregaLista',
      component: () => import('@/views/AgendamentoEntrega/AgendamentoEntregaLista.vue'),
    },
    //from JDamazio
    {
      path: '/Cesta',
      name: 'Cesta',
      component: () => import('@/views/Cesta/Cesta.vue'),
    },
    {
      path: '/cestaMobile',
      name: 'cestaMobile',
      component: () => import('@/views/Cesta/CestaMobile.vue'),
      meta: { layout: 'full', },
    },
    {
      path: '/relAcompanharOsTi',
      name: 'relAcompanharOsTi',
      component: () => import('@/views/Report/RelAcompanharOsTI.vue'),
    },
    {
      path: '/relDanfeSimplificada',
      name: '/relDanfeSimplificada',
      component: () => import('@/views/Report/RelDanfeSimplificada.vue')
    },
    //final
    {
      path: '/relAcompanhamentoPedido',
      name: 'relAcompanhamentoPedido',
      component: () => import('@/views/Report/RelAcompanhamentoPedido.vue'),
    },
    {
      path: '/prodHomologado',
      name: 'prodHomologado',
      component: () => import('@/views/ProdutosHomologados/Produtos.vue'),
    },
    {
      path: '/fornecedorWF',
      name: 'fornecedorWF',
      component: () => import('@/views/ParceiroWF/FornecedorWF.vue'),
    },
    {
      path: '/fornecedorWFDetalhe',
      name: 'fornecedorWFDetalhe',
      component: () => import('@/views/ParceiroWF/FornecedorWFDetalhe.vue'),
    },
    {
      path: '/fornecedorWFDetalhe/:codparc',
      name: 'fornecedorWFDetalhe',
      component: () => import('@/views/ParceiroWF/FornecedorWFDetalhe.vue'),
    },
    {
      path: '/formularios/rh/FacSubordinados',
      name: 'facSubordinados',
      component: () => import('@/views/Formularios/RH/FacSubordinados.vue'),
    },
    {
      path: '/formularios/rh/Fac/:codemp/:codfunc',
      name: 'facSubordinados',
      component: () => import('@/views/Formularios/RH/FacDetalhe.vue'),
    },
    {
      path: '/biblioteca',
      name: 'biblioteca',
      beforeEnter() {
        window.open('https://biblioteca.vapza.com.br/login/Sankhya', '_blank')
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },

    {
      path: '/MensagemOut',
      name: 'MensagemOut',
      component: () => import('@/views/error/MensagemOut.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('@/views/error/MensagemOut.vue'),
      meta: {
        layout: 'full',
      },
    },

    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/etiquetaOP',
      name: 'etiquetaOP',
      component: () => import('@/views/Etiquetas/EtiquetaOP.vue'),
    },
    {
      path: '/dashBoletimDiarioVenda',
      name: 'dashBoletimDiarioVenda',
      component: () => import('@/views/Report/DashBoletimDiarioVenda.vue'),
    },
    {
      path: '/dashBoletimDiarioVendaAnalitico',
      name: 'dashBoletimDiarioVendaAnalitico',
      component: () => import('@/views/Report/DashBoletimDiarioVendaAnalitico.vue'),
    },
    {
      path: '/relTabelaPreco',
      name: 'relTabelaPreco',
      component: () => import('@/views/Report/RelTabelaPreco.vue'),
    },
    {
      path: '/relVendaProdutoSGI',
      name: 'relVendaProdutoSGI',
      component: () => import('@/views/Report/RelVendaProdutoSGI.vue'),
    },
    {
      path: '/relVendaProdutoQualidade',
      name: 'relVendaProdutoQualidade',
      component: () => import('@/views/Report/RelVendaProdutoQualidade.vue'),
    },
    {
      path: '/relShelfLifefLocalPA',
      name: 'relShelfLifefLocalPA',
      component: () => import('@/views/Report/RelShelfLifefLocalPA.vue'),
    },
    {
      path: '/relAcompanhamentoVerba',
      name: 'relAcompanhamentoVerba',
      component: () => import('@/views/Report/RelAcompanhamentoVerba.vue'),
    },
    {
      path: '/relTeste',
      name: 'relTeste',
      component: () => import('@/views/Report/RelTeste.vue'),
    },
    {
      path: '/dashDespesasCRCC',
      name: 'dashDespesasCRCC',
      component: () => import('@/views/Report/DashDespesasCRCC.vue'),
      meta: {
        layout: route => route.query.fullLayout === 'true' ? 'full' : 'full',
      },

    },
    {
      path: '/uploadDataEntregaNFe',
      name: 'uploadDataEntregaNFe',
      component: () => import('@/views/RotinasUpXLSX/UploadDataEntregaNFe.vue'),
    },
    {
      path: '/uploadVinculaEntregaCorreioNFe',
      name: 'uploadVinculaEntregaCorreioNFe',
      component: () => import('@/views/RotinasUpXLSX/UploadVinculaEntregaCorreioNFe.vue'),
    },
    {
      path: '/qsGeral/:id',
      name: 'qsGeral',
      component: () => import('@/views/Questionario/QsGeral.vue'),
    },
    {
      path: '/upDDA',
      name: 'upDDA',
      component: () => import('@/views/DDA/UploadDDA.vue'),
    },
    {
      path: '/matchDDA',
      name: 'matchDDA',
      component: () => import('@/views/DDA/MatchDDA.vue'),
    },
    // {

    //   path: '/qsAberto/:id/:numos/:codusuencode',
    //   name: 'qsAberto',
    //   component: () => import('@/views/Questionario/QsAberto.vue'),
    //   meta: {
    //     layout: 'full',
    //   },

    // },
    {
      path: '/qsAberto',
      name: 'qsAberto',
      component: () => import('@/views/Questionario/QsAberto.vue'),
      meta: { layout: 'full', },
      // props: route => (
      //   {
      //     param1: route.params.param1,
      //     param2: route.params.param2,
      //     param3: route.params.param3
      //   }
      // )
    },
    {
      path: '/apontamentoProducao',
      name: 'apontamentoProducao',
      component: () => import('@/views/OperacoesProducao/ApontamentoProducao.vue'),
    },
    {
      path: '/apontamentoProducaoDetalhe/:idiproc',
      name: 'apontamentoProducaoDetalhe',
      component: () => import('@/views/OperacoesProducao/ApontamentoProducaoDetalhe.vue'),
      meta: { layout: 'full', },
    },
    {
      path: '/apontamentoProducaoDetalheForm/:ad_ciclo/:idefx',
      name: 'apontamentoProducaoDetalheForm',
      component: () => import('@/views/OperacoesProducao/ApontamentoProducaoDetalheForm.vue'),
      meta: { layout: 'full', },
    },
    {
      path: '/apontamentoProducaoDetalheMateriais/:ad_ciclo',
      name: 'apontamentoProducaoDetalheMateriais',
      component: () => import('@/views/OperacoesProducao/ApontamentoProducaoDetalheMateriais.vue'),
      meta: { layout: 'full', },
    },
    {
      path: '/TermoAutorizacaoEntrada',
      name: 'TermoAutorizacaoEntrada',
      component: () => import('@/views/AutorizacaoEntrada/TermoAutorizacaoEntrada.vue'),
      props: true,
      meta: {
        layout: 'full',
      },
    },

  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})


router.beforeEach((to, from, next) => {
  // Verifica se a query 'fullLayout' está presente
  if (to.query.fullLayout === 'true') {
    // Define o layout dinamicamente
    to.meta.layout = 'full';
  } else {
    if (to.query.fullLayout === 'false'){
    to.meta.layout = 'default';
    }
  }
  next();
});

export default router
